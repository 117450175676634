import React from 'react'
import Styled from './styled.module.css'
import ISOImg from '../../../assets/img/clients/iso-27001-3.png'

export default function Content() {
    return (
        <div className="container">
            <section className={Styled.content}>
                <div className={Styled.titleWrapper}>
                    <h1 className="lg-title bold">We are certified<br /> for ISO 27001.</h1>
                    <img alt="ISO 270001" src={ISOImg}></img>
                </div>
                <h2 className="sm-title bold">
                    ISO 27001 is the global gold standard for securing information. The Information Security Management System (ISMS) that ISO 27001 provides enables Integra to operate a data secure organization.
                </h2>
                <p className="lg-text">
                    Very few companies actually achieve ISO 27001 certification. According to ISO.org, only 28,426 companies worldwide were certified in 2016 -- among them Xerox, Pfizer and Vodafone. We are also proud to be among the select group of companies who care about customer’s data.
                </p>
                <h2 className="md-title bold">
                    Data security, privacy and confidentiality of your information
                </h2>
                <p className="lg-text">
                    When you outsource your tasks to a supplier, it must be in trusted hands. As a business, the security of your data is your responsibility and you have to ensure that it is properly safe guarded. We are fully aware of this and that is why we have taken every precaution to safeguard your data when it is with us. This is the primary reason why we have implemented ISO 27001 at our branch in India.

                </p>
                <p className="lg-text">
                    We deal with very sensitive information such as bank logins, credit card logins, social security, information etc. on a daily basis. We are extremely aware of the sensitiveness of the data and have taken all possible measures to ensure the safety of your data. It is only due to our strict data handling procedures, we have never had any security issues since our company inception (2004). It is also the reason why our clients trust us with all their confidential information which has allowed us to grow to hundreds of staff.
                </p>
                <p className="lg-text">
                    We also are a Pennsylvania, USA Corporation and we abide by the same privacy and confidentiality laws as you do.
                </p>
                <p className="lg-text">
                    As a requirement of ISO 27001 information security following are the points we have implemented to protect your data. <strong>You can also view the certificate</strong> <a target="_blank" href="/ISO-27001-certificate.pdf"><strong style={{ textDecoration: "underline" }}>here</strong></a>.
                </p>
                <h2 className="sm-title">Physical and Environmental Controls</h2>
                <ul>
                    <li className="lg-text">
                        24/7 security guards at our building
                    </li>
                    <li className="lg-text">
                        Finger print scanner access doors to prevent entry of un-authorized personnel
                    </li>
                    <li className="lg-text">
                        Computing equipment in access-controlled areas
                    </li>
                    <li className="lg-text">
                        Humidity and temperature control with alarm placed in server rooms
                    </li>
                    <li className="lg-text">
                        Diesel generators power back up with on-site diesel fuel storage
                    </li>
                    <li className="lg-text">
                        Uninterruptable power systems (UPS)
                    </li>
                </ul>
                <h2 className="sm-title bold">Operational Security Controls</h2>
                <ul>
                    <li className="lg-text">
                        High-end firewall gateway which provides security, web control, and application control.

                    </li>
                    <li className="lg-text">
                        Symantec end point protection is used to prevent, detect, and eradicate malware along with device control.

                    </li>
                    <li className="lg-text">
                        Connected to the Internet from multiple Internet Service Providers served from multiple telecommunication provider Points of Presence.
                    </li>
                    <li className="lg-text">
                        Information Security staff monitors notification from various internal systems.
                    </li>
                    <li className="lg-text">
                        Active Directory Authentication is used for User access control and network access.
                    </li>
                    <li className="lg-text">
                        Restricted Internet access
                    </li>
                    <li className="lg-text">
                        Our high security servers are managed from USA data centers
                    </li>
                    <li className="lg-text">
                        No removable drives (CD/DVD) in the operations floor
                    </li>
                    <li className="lg-text">
                        Activity monitoring software is installed in all our computers
                    </li>
                    <li className="lg-text">
                        All our systems are access restricted by multiple levels of password protection
                    </li>
                    <li className="lg-text">
                        No printers, USB based pen devices and DVD/CD drives on the work floor
                    </li>
                    <li className="lg-text">
                        Computer networks are safe guarded by many levels of software
                    </li>
                    <li className="lg-text">
                        Your information is used only to process the tasks that you have assigned to our staff
                    </li>
                </ul>
                <h2 className="sm-title bold">Business continuity and disaster recovery</h2>
                <ul>
                    <li className="lg-text">
                        Well defined business continuity and disaster recovery processes
                    </li>
                    <li className="lg-text">
                        Multiple offices in the same city, offices in multiple cities and countries allows us to easily provide disaster recovery back up sites
                    </li>
                </ul>
                <h2 className="sm-title bold">Highlights of our data security</h2>
                <ul>
                    <li className="lg-text">
                        ISO 27001 information security international standard certified
                    </li>
                    <li className="lg-text">
                        Payment card industry (PCI) data security standard approved
                    </li>
                    <li className="lg-text">
                        PCI certified for handling and processing credit card information
                  </li>
                    <li className="lg-text">
                        HIPAA compliant for handling confidential health and medical records
                  </li>
                    <li className="lg-text">
                        Approved by ministry of communication & information technology (STPI, India)
                  </li>
                    <li className="lg-text">
                        STPI approval number: 5798-Nov 2007
                  </li>
                    <li className="lg-text">
                        Registered under data protection act with UK information commissioner. UK data protection registration number: Z3331950 (since 2009)
                  </li>
                </ul>
                <h2 className="sm-title bold">With all these implementations and processes, you can be assured that your data is safe with us.</h2>
            </section>
        </div>
    )
}

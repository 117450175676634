import React from "react"
import Divider from "../../../assets/img/divider.svg"
import Styled from "./styled.module.css"

function Hero({ obj }) {
  return (
    <section style={{
      backgroundImage: `url('${require("../../../assets/img/industries/hero/" + obj.path + ".png")}')`
    }} className={Styled.section} >
      <div className={`${Styled.heroWrapper} container`}>
        <div className={Styled.heroDescription}>
          <h1 dangerouslySetInnerHTML={{ __html: obj.title }} className="md-title bold text-gray">
          </h1>
          <p dangerouslySetInnerHTML={{ __html: obj.content }} className="lg-text text-gray">
          </p>
        </div>
      </div>
      <img className={Styled.divider} alt="Divider" src={Divider}></img>

    </section >
  )
}

export default Hero

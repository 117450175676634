import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import Hero from "../components/PrivacyPage/Hero"
import Content from "../components/ISOPage/Content"
import "../assets/css/normalize.css"
import "../assets/css/custom.css"
import FeaturedImage from "../assets/img/integra-featured-image.png"

export default function Home() {
  return (
    <>
      <Helmet
        title="Worried about the security of your data? Find how we protect your data."
        meta={[
          {
            name: `description`,
            content:
              "Worried about the security of your outsourced business data? Your business information is highly secure with us. We use multiple methods to protect your information. Read more from this page",
          },
          {
            name: "keywords",
            content:
              "Data Security, Business Data Security, Business Security, Outsource Data Protection, Data Protection, Outsource Protection, Business Data, Outsource Data, Outsource Data Security, Business Data Protection, Outsource Security, Business Protection, Business,",
          },
        ]}
      >
        <meta property="og:url" content="https://www.globalintegra.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Worried about the security of your data? Find how we protect your data."
        />
        <meta
          property="og:description"
          content="Worried about the security of your outsourced business data? Your business information is highly secure with us. We use multiple methods to protect your information. Read more from this page"
        />
        <meta property="og:image" content={FeaturedImage} />

        <meta name="twitter:card" content="summary" />
        <meta property="twitter:domain" content="globalintegra.com" />
        <meta property="twitter:url" content="https://www.globalintegra.com/" />
        <meta
          name="twitter:title"
          content="Worried about the security of your data? Find how we protect your data."
        />
        <meta
          name="twitter:description"
          content="Worried about the security of your outsourced business data? Your business information is highly secure with us. We use multiple methods to protect your information. Read more from this page"
        />
        <meta name="twitter:image" content={FeaturedImage}></meta>
      </Helmet>
      <Layout>
        <main>
          <Hero
            obj={{
              path: "iso",
              title: "Data Security <br/>ISO 27001",
              content:
                "Security, privacy and confidentiality <br/> of your information.",
            }}
          />
          <Content />
        </main>
      </Layout>
    </>
  )
}
